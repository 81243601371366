import '../global/styles.css'

import React, { ReactElement, ReactNode } from 'react'

export default function Styles({
  children
}: {
  children: ReactNode
}): ReactElement {
  return <>{children}</>
}
