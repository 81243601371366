exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-page-learn-and-build-build-tsx": () => import("./../../../src/components/templates/PageLearnAndBuild/Build.tsx" /* webpackChunkName: "component---src-components-templates-page-learn-and-build-build-tsx" */),
  "component---src-components-templates-page-learn-and-build-learn-tsx": () => import("./../../../src/components/templates/PageLearnAndBuild/Learn.tsx" /* webpackChunkName: "component---src-components-templates-page-learn-and-build-learn-tsx" */),
  "component---src-components-templates-page-markdown-tsx": () => import("./../../../src/components/templates/PageMarkdown.tsx" /* webpackChunkName: "component---src-components-templates-page-markdown-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-asi-token-tsx": () => import("./../../../src/pages/about-us/asi-token.tsx" /* webpackChunkName: "component---src-pages-about-us-asi-token-tsx" */),
  "component---src-pages-about-us-foundation-tsx": () => import("./../../../src/pages/about-us/foundation.tsx" /* webpackChunkName: "component---src-pages-about-us-foundation-tsx" */),
  "component---src-pages-about-us-media-kit-tsx": () => import("./../../../src/pages/about-us/media-kit.tsx" /* webpackChunkName: "component---src-pages-about-us-media-kit-tsx" */),
  "component---src-pages-build-data-science-hub-tsx": () => import("./../../../src/pages/build/data-science-hub.tsx" /* webpackChunkName: "component---src-pages-build-data-science-hub-tsx" */),
  "component---src-pages-build-developer-hub-tsx": () => import("./../../../src/pages/build/developer-hub.tsx" /* webpackChunkName: "component---src-pages-build-developer-hub-tsx" */),
  "component---src-pages-build-shipyard-tsx": () => import("./../../../src/pages/build/shipyard.tsx" /* webpackChunkName: "component---src-pages-build-shipyard-tsx" */),
  "component---src-pages-earn-data-challenges-tsx": () => import("./../../../src/pages/earn/data-challenges.tsx" /* webpackChunkName: "component---src-pages-earn-data-challenges-tsx" */),
  "component---src-pages-explore-community-tsx": () => import("./../../../src/pages/explore/community.tsx" /* webpackChunkName: "component---src-pages-explore-community-tsx" */),
  "component---src-pages-explore-ecosystem-tsx": () => import("./../../../src/pages/explore/ecosystem.tsx" /* webpackChunkName: "component---src-pages-explore-ecosystem-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-templates-decentralized-data-marketplace-tsx": () => import("./../../../src/pages/templates/decentralized-data-marketplace.tsx" /* webpackChunkName: "component---src-pages-templates-decentralized-data-marketplace-tsx" */),
  "component---src-pages-templates-index-tsx": () => import("./../../../src/pages/templates/index.tsx" /* webpackChunkName: "component---src-pages-templates-index-tsx" */),
  "component---src-pages-templates-tokengated-platform-tsx": () => import("./../../../src/pages/templates/tokengated-platform.tsx" /* webpackChunkName: "component---src-pages-templates-tokengated-platform-tsx" */)
}

